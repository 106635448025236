import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankYouPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Thank you!" />
    <div className="aux-page text-center">
      <div className="container">
        <h1 className="display-4">Thank you!</h1>
        <p className="lead mb-5">
          We have reached your message and will get back to you soon!
        </p>
        <Link to="/" className="btn btn-primary">
          Take Me Home
        </Link>
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
